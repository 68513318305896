.contact-page header {
	background-position: center;
	background-image: linear-gradient(rgba(5, 10, 35, 0.5), rgba(5, 10, 35, 0.5)),
		url(https://res.cloudinary.com/dj3epjudt/image/upload/v1712580160/kasbi-frontend/contact-page-header.jpg);
	background-position: top;
}

#contact-form {
	width: 50%;
}

#contact-form .form-control:focus,
#contact-form .form-select:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

@media only screen and (max-width: 992px) {
	#contact-form {
		width: 75%;
	}
}

@media only screen and (max-width: 576px) {
	#contact-form {
		width: 100%;
	}
}
