.courses-page header {
	background-image: linear-gradient(rgba(5, 10, 35, 0.5), rgba(5, 10, 35, 0.5)),
		url(https://res.cloudinary.com/dj3epjudt/image/upload/v1712578681/kasbi-frontend/bg-course.jpg);
	background-position: center;
}

.courses-page .card:hover {
	cursor: pointer;
}

.course__image {
	object-fit: cover;
}
