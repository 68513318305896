.home-page header {
	background-image: linear-gradient(rgba(5, 10, 35, 0.5), rgba(5, 10, 35, 0.5)),
		url(../.././assets/images/bg-home.jpg);
}

header {
	background-size: cover;
	background-position: top;
}

.blog-section {
	background-image: linear-gradient(rgba(5, 10, 35, 0.5), rgba(5, 10, 35, 0.5)),
		url(https://res.cloudinary.com/dj3epjudt/image/upload/v1712578681/kasbi-frontend/shcifhslysobztlghv0o.jpg);
	background-size: cover;
	background-position: center;
}

@media screen and (max-width: 767px) {
	.vedio {
		width: 100%;
	}
}
